import * as React from 'react';
import { useEffect } from 'react';
import { usePage } from '../../context/pageContext';

const MainMenuPageWrapper = ({ children }) => {
	const [menuOpen, setMenuOpen, scrollPosition] = usePage();

	useEffect(() => {
		const handleScroll = () => {
			window.scrollTo(0, scrollPosition);
		};

		if (menuOpen) {
			window.addEventListener('scroll', handleScroll);
			document.documentElement.className = 'noscroll';
		} else {
			document.documentElement.className = '';
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [scrollPosition]);

	return <div className='page-wrapper'>{children}</div>;
};

export default MainMenuPageWrapper;
