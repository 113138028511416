import './src/theme/styles.scss';
import * as React from 'react';
import Layout from './src/components/layout/layout';



const wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>
}

export { wrapPageElement };