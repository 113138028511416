import * as React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';

const PageContext = createContext([false, null, 0]);

const usePage = () => useContext(PageContext);



const PageProvider = ({ children }) => {
	const [ menuOpen, setMenuOpen ] = useState(false);
	const [ scrollPosition, setScrollPosition ] = useState(null);

	useEffect(() => {
		if(menuOpen) setScrollPosition(window.pageYOffset);
		if (!menuOpen) setScrollPosition(null);
	}, [menuOpen]);

	return (
		<PageContext.Provider value={[menuOpen, setMenuOpen, scrollPosition]}>
			{children}
		</PageContext.Provider>
	)
}

export { PageContext, PageProvider, usePage };