import * as React from 'react';
import MainMenuPageWrapper from '../mainMenu/mainMenuPageWrapper';
import { PageProvider } from '../../context/pageContext';

const Layout = ({children, ...rest}) => {
	return (
		<PageProvider>
			<div {...rest}>
				<MainMenuPageWrapper>
					{children}
				</MainMenuPageWrapper>
			</div>
		</PageProvider>
	)
};

export default Layout;